<template>
    <div class="auth-container">
        <Navbar />
        <router-view class="auth-container__inner" />
        <img src="@/assets/images/backgrounds/preview-section-bg.png" alt="preview-section-bg" class="image-top" />
        <img src="@/assets/images/backgrounds/auth-layout-bg.png" alt="auth-layout-bg" class="image-bottom" />
    </div>
</template>

<script>
    import Navbar from '@/components/Common/Navbar/Navbar.vue';

    export default {
        name: 'AuthLayout',
        components: { Navbar },
    };
</script>

<style lang="scss" scoped>
    .auth-container {
        height: 100vh;
        overflow-x: hidden;

        position: relative;

        padding-top: 75px;
        padding-bottom: 50px;

        &__inner {
            @include row-align-center-justify-center;

            padding: 0 12px;
            margin-top: 100px;

            @include media($lg) {
                margin-top: 150px;
            }
        }

        .image-top,
        .image-bottom {
            z-index: -1;
            position: absolute;
        }

        .image-top {
            top: 0;
            right: 0;

            width: 261px;
            height: 540px;
        }

        .image-bottom {
            bottom: 0;

            width: 329px;
            height: 553px;
        }
    }

    @include media($xl) {
        .auth-container {
            overflow-y: hidden;
        }
    }
</style>
